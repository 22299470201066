.s_container {
  position: relative;
  margin-top: 10px;
}
.s_container .swiper {
  width: 70%;
  height: 12rem;
  position: unset;
}
.s_container .swiper-slide {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
}
.s_container .swiper-button-prev {
  left: 6rem;
  color: black;
}
.s_container .swiper-button-next {
  right: 6rem;
  color: black;
}

.l_slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  display: flex;
  flex-direction: column;
}
.name > :nth-child(1) {
  font-size: 1.4rem;
  font-weight: 600;
}
.name > :nth-child(2) {
  font-size: 0.7rem;
}
.l_slide > :nth-child(2) {
  font-weight: bold;
  font-size: 2rem;
}
.l_slide > :nth-child(3) {
  font-size: 0.6;
  border: 1px solid var(--black);
  padding: 5px 10px;
  width: max-content;
  border-radius: 15px;
}
.img_sld {
  transform: rotate(-20deg);
  position: absolute;
  right: 0;
  width: 40%;
  height: 100%;
  bottom: -20%;
}
@media screen and (max-width: 856px) {
  .s_container,
  .swiper {
    width: 90%;
    height: 10rem;
  }
  .s_container .swiper-button-prev,
  .s_container .swiper-button-next {
    display: none;
  }
}
@media screen and (max-width: 640px) {
}
