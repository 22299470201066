.container_all {
  padding: 0 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
.container_all > :first-child {
  position: absolute;
  width: 8rem;
  left: 30%;
  top: -3rem;
}
.productall {
  display: grid;
  width: 90%;
  grid-template-columns: 25% auto;
}
.product_menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-weight: 500;
  font-size: 1.3rem;
}
.product_menu > li:hover {
  color: var(--PINK);
  cursor: pointer;
}
.list_product {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  height: 25rem;
  overflow-y: scroll;
  grid-gap: 2rem;
  justify-content: space-between;
}
.pproduct > .img_sld {
  top: 3rem;
  width: 6rem;
  height: 11rem;
}
.pproduct {
  background-color: white;
  width: 12rem;
  height: 8rem;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  border-radius: 1rem;
}
@media screen and (max-width: 856px) {
  .container_all {
    gap: 1rem;
  }
  .container_all > :nth-child(1) {
    display: none;
  }
  .productall {
    grid-template-columns: none;
    gap: 1rem;
  }
  .product_menu {
    flex-direction: row;
  }
}
@media screen and (max-width: 640px) {
  .product_menu {
    margin-left: -2rem;
    gap: 0.5rem;
    flex-wrap: wrap;
    width: 100%;
    font-size: 1.2rem;
  }
  .container_all > h1 {
    text-align: center;
  }
}
