.testimonials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
}
.testimonial_wrapper {
  display: grid;
  align-items: flex-end;
  width: 100%;
  grid-template-columns: 1fr 2fr 1fr;
}
.testimonial_wrapper > img {
  width: 25rem;
  justify-self: center;
}
.container_testimonial {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.container_testimonial > :nth-child(1) {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
}
.container_testimonial > :nth-child(2) {
  font-size: 0.8rem;
  text-transform: uppercase;
  display: block;
}
.testimonial_wrapper > :nth-child(3) {
  text-align: right;
}
.review {
  font-size: 2rem;
  font-weight: bold;
  text-decoration: uppercase;
  text-align: center;
  padding: 20px 20px;
}
.coursel {
  width: 100%;
}
.tcarousel {
  padding: 2rem;
}
.testmo {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
  box-shadow: 0rem 1rem 3rem -50px #7d7d7d;
}
.testmo > img {
  position: absolute;
  width: 3rem;
  top: -1.5em;
  left: 45%;
}
.testmo > span:nth-of-type(1) {
  align-items: center;
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin-top: 2rem;
}
.testmo > span:nth-of-type(2) {
  font-weight: 500;
}
.testmo > hr {
  height: 1px;
  width: 80%;
  background-color: rgb(198, 198, 198);
  border: none;
}
@media screen and (max-width: 640px) {
  .testimonial_wrapper {
    grid-template-columns: 1fr;
  }
  .testimonial_wrapper > img {
    width: 80vw;
  }
  .testimonial_wrapper > :nth-child(3),
  .testimonial_wrapper > :nth-child(1) {
    text-align: center;
  }
}
