@import url("https://fonts.google.com/share?selection.family=Poppins:wght@400;500;600;700");
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: var(--bg);
  transition: all 3ms ease;
  color: var(--black);
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
